html {
	font-size:62.5%;
}
:root {
  /* --adm-font-family: 'Jua';  */
  --color-main-50: #F1FCF6;
  --color-main-500: #40DF9F;
  --color-main-600: #2EBF93;
  --color-grayb-50: #F8FAF9;
  --color-grayb-100: #F5F5F5;
  --color-grayb-200: #EEEEEE;
  --color-grayb-300: #E0E0E0;
  --color-grayb-500: #9E9E9E;
  --color-today-date: #788991;
  --bgcolor-today-date: #F8F8F8;
  --color-white: #ffffff;
  --color-black: #202020;
  --header-height: 4.8rem;
  --bottom-height: 5.6rem;  /* (7.3에서 수정)56 + 34/2px 하단 탭 height. 특히 BottomPopUp.jsx에서 true, false로 동작*/
  --color-text: #202020;
  --color-holiday-not-thismonth:#FFC0BB;
  --color-holiday:#FF567B;
}
.head-h1 {
  font-size: 2.6rem;
  font-weight: bold;
}
.head-h2 {
  font-size: 2.2rem;
  font-weight: bold;
}
.head-h3 {
  font-size: 2.4rem;
  font-weight: bold;
}
.head-h4 {
  font-size: 2rem;
  font-weight: bold;
}
.head-h5 {
  font-size: 1.8rem;
  font-weight: bold;
}
.head-h5-m {
  font-size: 1.8rem;
}
.sub-title {
  font-size: 1.6rem;
}
.bold {
  font-weight: bold;
}
.caption {
  font-size: 1.2rem;
}
