@import '_variables';
@import 'mixin';
// S: header 요소들 모두 layout에서 관리
header.fixed {
    position: fixed;
    z-index: 999;
    // z-index:999 모달 dim보다 아래숫자라 dim이 덮을 수 있음
    left: 0;
    top: 0;
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    width: 100%;
    max-width: 72rem;
    > div {
        display: grid;
        grid-template-columns: 4.8rem 1fr 4.8rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4.8rem;
            height: 4.8rem;
        }
        .header-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4.8rem;
            height: 4.8rem;
        }
    }
    .search-header {
        grid-template-columns: 4.8rem 1fr 2rem;
        padding-left: 0;
        padding-right: 0;
    }
    .join-header {
        display: flex;
        justify-content: center;
    }
}
.fix-bottom {
    position: fixed;
    bottom: 0;
    z-index: 999;
}
main:has(header.fixed) {
    .wrap {
        padding-top: 4.8rem;
    }
    .subscription-cancel-step1 {
        padding-top: 3rem;
    }
}
main:has(.splash) {
    main {
        padding: 0 2rem;
    }
    .wrap.splash {
        height: calc(100vh - 5.4rem);
    }
}
body:has(.service-info) {
    main {
        height: calc(100vh - 8rem);
        .list-link-box {
            margin-top: 4rem;
        }
    }
}
body:has(.setting-animal) {
    main {
        height: auto;
        .setting-animal {
            height: calc(100vh - 15.5rem);
            padding-bottom: 0 !important;
        }
        .join {
            padding: 0 !important;
        }
    }
}
@media (min-width: 720px) {
    main:has(.splash) {
        .wrap.splash {
            height: calc(100vh - 8rem);
        }
        button {
            margin-bottom: 2rem !important;
        }
    }
    body:has(.service-info) {
        main {
            height: calc(100vh - 10rem);
        }
    }
    body:has(.setting-animal) {
        main {
            height: calc(100vh - 5rem);
            .setting-animal {
                height: calc(100vh - 17.5rem);
            }
            .button-wrap {
                padding-bottom: 2rem;
            }
        }
    }
}
body {
    &:has(.real-home, .calendar-header2, .real-time-wrap) {
        .layout-header-nav-bar-box {
            .adm-image {
                width: 3.6rem !important;
                height: 3.6rem !important;
            }
        }
    }
    &:has(.real-home) {
        .layout-header-nav-bar-box {
            .adm-image {
                margin-right: 1.7rem;
            }
        }
        .real-home {
            .slide-img {
                .adm-image {
                    position: relative;
                    height: 100%;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to top, #000, transparent);
                        opacity: 0.3;
                    }
                }
            }
        }
    }
}
body {
    &:has(.real-time-wrap) {
        .layout-header-logo {
            margin-left: 1rem;
        }
    }
}

// header+div{
//   position: absolute;
//   top: 4.8rem;
// }
// body컨텐츠 영역이 쪼그라들어서 없앴는데. (/popular) 이거 적용돼야하는 페이지 발견하면 따로 클래스주기

.layout-header-page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 400;
}
.layout-header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.2rem;
}
.layout-header-logo .logo-text {
    margin-left: 0.8rem;
    font-family: 'Jua';
    font-weight: normal;
    font-size: 2.8rem;
    color: $color-main-500;
    line-height: 1.3;
}
.layout-header-logo .menu-title {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
}
.layout-header-nav-bar {
    display: flex;
    // &.diary-wallet-bar{
    //   padding-right: 1rem;
    // }
}

// /diary/add, /wallet/account/book/write, /community/add, /community/1#
// 입력위치 : <div className="page-header header__border">
.header__border {
    border-bottom: 0.05rem solid $color-grayb-200;
}

// E: header 요소들 모두 layout에서 관리

.layout-content {
    -webkit-tap-highlight-color: rgba(
        255,
        255,
        255,
        0
    ); /* 버튼 Touch 시 나오는 음영 지우기 */
    user-select: none; /* select 안되게 하기 */
    -webkit-touch-callout: none; /* link Long touch 막기 */
}
.layout-body {
    position: absolute;
    top: 4.8rem; /* header height */
    bottom: 5.6rem; /* footer height */
    /* left:0; */
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    width: 100%;
    max-width: 72rem;
    background: #ffffff;
    // overflow-y:auto;
}

.layout-body.no-header {
    top: 0; /* header height */
}
.layout-body.no-footer {
    bottom: 0; /* footer height */
}

.layout-scroll-body {
    position: absolute;
    // 이거 해야 어떤 페이지가 쓸데없이 스크롤 생기는 일이 없어짐 (ex./wallet)
    width: 100%;
    // height: 100%;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    // overflow-y:auto;
    /* background: red; */
    &:has(.cmp-simple-calendar-week) {
        overflow-x: hidden;
    }
}
.layout-header {
    position: fixed;
    top: 0;
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    width: 100%;
    max-width: 72rem;
    height: 4.8rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 1rem;
}

.layout-header2 {
    position: fixed;
    z-index: 1;
    /* left: 0; */
    top: 0;
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    width: 100%;
    max-width: 72rem;
    height: 4.8rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}
.layout-header3 {
    position: fixed;
    z-index: 1;
    /* left: 0; */
    top: 0;
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    width: 100%;
    max-width: 72rem;
    height: 4.8rem;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 4.8rem 1fr 4.8rem;
    grid-template-rows: 4.8rem;
}

.layout-footer {
    position: fixed;
    z-index: 100;
    /* left: 0; */
    bottom: 0;
    left: 50%; /* center */
    transform: translate(-50%, 0); /* center */
    // padding: 1rem 2rem;
    width: 100%;
    max-width: 72rem;
    height: var(--bottom-height);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;

    /* 추가 */
    box-shadow:
        0px 19px 38px 0px rgba(0, 0, 0, 0.3),
        0px 15px 12px 0px rgba(0, 0, 0, 0.22);
    // box-shadow: 0 1.9rem 3.8rem 0 rgba(0, 0, 0, 0.30);
    border-top: 0.1rem solid #eaeeed;
    border-radius: 1.6rem 1.6rem 0 0;
}
.layout-footer-nav-bar {
    width: 5.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    margin-top: 0.3rem;
    background-color: #ffffff;
    cursor: pointer;
    > div {
        img {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}

.divider-01 {
    border-top: 0.1rem solid rgba(250, 250, 250, 1);
}
.divider-01-E2E4E4 {
    border-top: 0.1rem solid #e2e4e4;
}
.divider-01-FAFAFA {
    border-top: 0.1rem solid #fafafa;
}
.divider-02 {
    border-top: 0.2rem solid rgba(250, 250, 250, 1);
}
.divider-005 {
    border-top: 0.05rem solid $color-grayb-100;
}
.divider-005-grayb200 {
    border-top: 0.05rem solid $color-grayb-200;
}
.divider-10 {
    border-top: 1rem solid $color-grayb-50;
}
.divider-16 {
    border-top: 1.6rem solid $color-grayb-50;
}
.divider-20 {
    border-top: 2rem solid $color-grayb-50;
}

/* tablet */
@media (min-width: 768px) {
    body .layout-scroll-body {
        // overflow-y:auto;
        //  /* overflow: hidden;Hide scrollbars */
        // -webkit-overflow-scrolling: auto;
    }
}
@media (min-width: $pcVersion) {
    .layout-scroll-body {
        // height:calc(100% - 1.8rem);
        // 하단에 safe-area처럼 생기는것
    }
    body:has(.community-detail) {
        .layout-body {
            // overflow-y: hidden;
        }
    }
}

// S:fix-bottom 요소들 모두 layout에서 관리

.bottom-fixed {
    width: 100%;
    max-width: 72rem;
    // background-color: #fff;
    .faq-bottom {
        margin: 1.4rem 2rem 3.4rem 2rem;
    }
}

.fix-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 72rem;
    &:has(.faq-bottom2) {
        background-color: #fff;
    }
    .button-wrap {
        &.data-import,
        &.data-export {
            padding-top: 1.6rem;
            padding-left: 2rem;
            padding-right: 2rem;
            // padding-bottom: 1.6rem;
        }
        &.setting-asset-modify,
        &.note-share-take,
        &.setting-asset-modify {
            .faq-bottom {
                padding-top: 1.6rem;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-bottom: 1.6rem;
            }
        }
        &.note-share-take {
            .faq-bottom {
                p.info-g {
                    @include body-me($color-grayb-500);
                }
            }
        }
        &.expend-group-modify {
            .faq-bottom {
                padding-top: 2.4rem;
                padding-left: 2.9rem;
                padding-right: 2.9rem;
                padding-bottom: 2.4rem;
            }
        }
    }
    .btn {
        display: block;
    }
    .normal {
        background-color: #36423d;
    }
    .disabled {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
    .active {
        background-color: $color-main-500;
        color: #fff;
        font-weight: 700;
    }
    .active-bk {
        background-color: #36423d;
        color: #fff;
        font-weight: 500;
    }
    .inactive {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
    .radius-16 {
        border-radius: 1.6rem;
    }
    .large-h54 {
        height: 5.4rem;
        font-size: 1.6rem;
    }
}

body:has(.profile-setting, .animal-add-main) {
    .fix-bottom,
    .button-wrap {
        position: initial !important;
        transform: initial;
    }
}
@media (min-width: 720px) {
    .fix-bottom {
        .button-wrap {
            &.data-import,
            &.data-export {
                padding-bottom: 2rem;
            }
        }
    }
}
.faq-bottom {
    padding: 1.6rem 2rem;
    background-color: $color-grayb-50;
    border-radius: 1rem;
    .data-export &,
    .data-import &,
    .setting-asset-modify &,
    .expend-group-modify & {
        text-align: left;
    }
    img {
        width: 2rem;
        height: 2rem;
    }
    .info-b {
        font-size: 1.6rem;
        font-weight: 700;
        color: #9e9e9e;
        margin-bottom: 1.8rem;
        span {
            @include sub-title-bold($color-grayb-600);
            margin-left: 0.4rem;
        }
    }
    .info-g {
        font-size: 1.4rem;
        font-weight: 400;
        color: $color-grayb-600;
        word-break: break-all;
    }
    .danger {
        color: $color-danger-500;
        font-weight: 700;
    }
    a {
        @include body-bold($color-main-600);
        margin-top: 0.8rem;
        word-break: break-all;
        // '데이터 백업' 텍스트 깨기
    }
    > :last-child {
        margin-bottom: 0;
    }
    .expend-group-modify &,
    .setting-asset-modify & {
        display: flex;
        flex-direction: column;
    }
    .black00 {
        @include body-bold($black00);
    }
}
.black00 {
    color: $black00;
}
.faq-bottom.handbook {
    margin-bottom: 5rem;
}
.faq-bottom2 {
    background-color: $color-grayb-50;
    border-radius: 1rem;
    border: 1px solid red;
    .info-b {
        font-size: 1.2rem;
        font-weight: 400;
        color: #000;
    }
    .info-g {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
    }
    .info-g2 {
        font-size: 1.2rem;
        font-weight: 400;
        color: #bdbdbd;
    }
    a.point-g {
        font-size: 1.2rem;
        font-weight: 700;
    }
}

.bottom-fixed {
    &.search {
        position: fixed;
        bottom: 0;
    }
}
@media (min-width: 720px) {
    .bottom-fixed {
        &.search {
            bottom: 2rem;
        }
    }
}
.bottom-faq {
    // border:1px solid red;
    .search & {
        margin: 0 2rem;
        padding: 1.6rem 2.9rem;
        border-radius: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        background: #fafafa;
    }
    .title {
        font-size: 1.4rem;
        font-weight: 700;
    }
    .content {
        color: $color-grayb-600;
    }
    a.point-g {
        display: inline-block;
        padding-top: 0.8rem;
        font-weight: 700;
        font-size: 1.4rem;
    }
}
// E:fix-bottom 요소들 모두 layout에서 관리

.only-mobile {
    display: none;
}
@media (max-width: 719px) {
    .only-mobile {
        display: block;
    }
}
body::-webkit-scrollbar {
    width: 10px;
}
body::-webkit-scrollbar-thumb {
    background-color: #7510f7;
}

// pc버전에서 빈공간 생기는 페이지
@media (min-width: 720px) {
    .community-detail {
        // height: calc( 100vh - 4.8rem)
    }
    .community-detail:has(.repl-box > .repl-list) {
        // height: 100%;
    }
    .community-detail:has(.repl-box > .default) {
        min-height: 100vh;
    }
    @media (min-width: 720px) {
        body:has(.community) {
            height: initial !important;
        }
    }
    .wrap {
        .member {
            .login {
                height: 100vh;
            }
        }

        &.notice:has(.empty-content) {
            height: 100vh;
        }
        &.notice:not(:has(.empty-content)) {
            height: auto;
        }
    }
}

@media (min-width: 720px) {
    body:has(.slide-wrap, .step-6) {
        .layout-scroll-body {
            height: initial;
        }
    }
    .slide-dots {
        margin-bottom: 2rem;
    }
    body:has(.step-2, .step-3, .step-4, .step-5) {
        .layout-scroll-body {
            height: 100%;
            // .button-wrap 하단에 붙이기 위함
        }
    }
    body:has(.step-4, .step-5, .step-6) {
        .layout-scroll-body {
            .button-wrap {
                padding-bottom: 2rem;
            }
        }
    }
}

.loading-component {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

body:has(.writing-button-wrap.myhistory) {
    .adm-center-popup-body {
        .alert-modal {
            .popup-wrap4 {
                .popup-title {
                    text-align: left;
                }
                .popup-center-footer {
                    padding: 0;
                    button {
                        margin: 0;
                        text-align: center !important;
                    }
                }
            }
        }
    }
}

body:has(.fixed-layout) {
    .layout-header2 {
        z-index: -1;
    }
    .layout-body {
        position: fixed !important;
        overflow: hidden !important;
    }
    .hand-book-detail-date {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }
    .account-box {
        height: 12rem;
    }
    @media (min-width: 720px) {
        body:has(.community) {
            .wrap.community-detail {
                height: initial !important;
            }
        }
    }
}
