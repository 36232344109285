html {
	font-size:62.5%;
}
:root {
  --adm-radius-s: 0.4rem;
  --adm-radius-m: 0.8rem;
  --adm-radius-l: 1.2rem;
  --adm-font-size-1:0.9rem;
  --adm-font-size-2: 1rem;
  --adm-font-size-3: 1.1rem;
  --adm-font-size-4: 1.2rem;
  --adm-font-size-5: 1.3rem;
  --adm-font-size-6: 1.4rem;
  --adm-font-size-7: 1.5rem;
  --adm-font-size-8: 1.6rem;
  --adm-font-size-9: 1.7rem;
  --adm-font-size-10: 1.8rem;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-yellow: #ff9f18;
  --adm-color-orange: #ff6430;
  --adm-color-wathet: #e7f1ff;
  --adm-color-text: var(--color-text);
  --adm-color-text-secondary: var(--color-text);
  --adm-color-weak: var(--color-text);
  --adm-color-light: var(--color-text);
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-highlight: var(--adm-color-danger);
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: 'Noto Sans';
  --adm-border-color: var(--adm-color-border);
}