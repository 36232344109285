@import "_variables";

.ext{
  width: 100%;
  &.social{
    //소셜 로그인
    .social-list{
      .account-mail{
        width: 100%;
        text-align: center;
        height: 10rem;
        overflow: hidden;
        border-radius: 1.6rem;
        margin-bottom: 4rem;
        font-weight: 400;
        font-size: 1.4rem;
        h4{
          padding: 1.2rem 1.6rem;
          position: relative;
          &:before{
            content:'';
            width: 3rem;
            height: 3rem;
            position: absolute;
            left: 1.6rem;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
        p{
          padding: 1.6rem;
        }
        &.google-login{
          border: 0.05rem solid $color-grayb-200;
          h4{
            border-right: 0.05rem solid $color-grayb-200;
            border-bottom: 0.05rem solid $color-grayb-200;
            border-left: 0.05rem solid $color-grayb-200;
            //top까지 하면 외부 선이랑 곂쳐서 두꺼워짐
            border-radius: 0 0 1.6rem 1.6rem;
            color: #000;
            background-color: $color-grayb-50;
            &:before{
              background:url('../images/ico_google_30.svg') no-repeat left 50%;
            }
          }
          p{
            color: #000;
          }
        }
        &.kakao-login{
          border: 0.05rem solid #FEE500;
          h4{
            background-color: #FEE500;
            color: #000;
            &:before{
              background:url('../images/ico_kakao_30.svg') no-repeat left 50%;
            }
          }
          p{
            color: #000;
          }
        }
        &.naver-login{
          border: 0.05rem solid #03C75A;
          h4{
            background-color: #03C75A;
            color: #fff;
            &:before{
              background:url('../images/ico_naver_30.svg') no-repeat left 50%;
            }
          }
          p{
            color: #000;
          }
        }
        &.apple-login{
          
          h4{
            background-color: #000;
            color: #fff;
            &:before{
              width: calc(100% - 1.6rem * 2);
              height: 100%;
              background: url('../images/ico_apple_30.svg') no-repeat left 50%;
            }
          }
          border: 0.05rem solid #000 ;
          box-sizing: border-box ;
          p{
            color: #000;
          }
        }
        .title-xs{
          width: 100%;
        }
      }
    }
  }

  // 회원탈퇴
  .withdrawal{
    padding: 0 2rem;
    margin: 4.2rem 0 0;
    .title-l{
      padding-left: 1.8rem;
    }
    .ext-img{
      padding: 4.8rem 1.8rem 4.8rem 1.8rem;
      text-align: center;
      img {
        border-radius: 1.6rem;
        overflow: hidden;
      }
    }
    .round-box{
      .overlap{
        font-size: 1.2rem;
        font-weight: 500;
        color: $color-grayb-500;
        margin-bottom: 1.4rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span{
          font-size: 1.2rem;
          font-weight: 500;
          color: #FF567B;
        }
      }
      ul{
        li{
          + li {
            margin-top: 1.4rem;
          }
          .checkbox2{
            label{
              vertical-align: bottom;
            }
            .check-mark{
              margin-right: 1.6rem;
            }
          }
          .ext-input{
            padding: 1.5rem 0.8rem;
            box-sizing: border-box;
            margin-top: 1.4rem;
            border-radius: 0.8rem;
            border: 0.1rem solid $color-grayb-500;
            input{
              width: 100%;
              height: 2rem;
              font-size: 1.4rem;
              font-weight: 500;
              color: $black00;
              border: none;
              &::placeholder{
                color: $color-grayb-500;
              }
              &:focus{
                caret-color: unset;
              }
            }
          }
        }
      }
    }
    .button-wrap2{
      padding: 2.4rem 0 3.2rem;
    }

    .text-group{
      font-size: 1.4rem;
      padding-left: 1.8rem;
      padding-right: 1.7rem;
      font-weight: 500;
      .item:first-child{
        display: flex;
        align-items: center;
      }
      .item:last-child{
        margin-top: 0.8rem;
      }
      .number-green{
        padding: 0 0.8rem;
        color:#2EBF93;
        font-weight:700;
        font-size: 1.6rem;
      }
      &.withdrawal2{
        padding-top: 6.4rem;
        .item{
          padding-top: 1.6rem;
          &:first-child{
            padding-top:0;
          }
        }
      }
    }
    &.withdrawal3{
      .reference{
       .reference-s2{
        padding-top: 0.8rem;
        padding-left: 1.8rem;
       } 
      }
    }
  }

  // .bell-setting{
  //   width: 100%;
  //   .list-link-box{
  //     padding: 0 2rem 4rem;
  //     border-bottom: 2rem solid $color-grayb-50;
  //     + .list-link-box{
  //       margin-top: 4rem;
  //     }
  //   }
  // }



}

.ext-text-light-gray{
  color: $color-grayb-600;
  font-weight: 500;
}
.ext-text{
  margin-bottom: 2.4rem;
  padding:0;
  font-size: 1.4rem;
  font-weight:500;
  span {
    &:last-child {
      margin-left: 0.4rem;
    }
  }
}
body:has(.ext) {
  .button-wrap {
    padding-top: 0;
  }
}

.subscription-cancel-step1 {
  .withdrawal {
    padding-bottom: 5rem;
    .round-box {
      margin-top: 3.2rem;
      ul {
        li {
          +li {
            margin-top: 1rem !important;
          }
          .checkbox {
            label {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
.subscription-cancel-step1, .subscription-cancel-step2 {
  .how-to-box {
    padding: 1.6rem 2rem;
    .title {
      display: flex;
      margin-bottom: 1.4rem;
    }
  }
}
.subscription-cancel-step2 {
  .withdrawal {
    margin-top: 8.8rem;
    .round-box {
      margin-top: 5.6rem;
    }
  }

  .ext-input {
    width: 100%;
    border-radius: 0.8rem;
    border: 0.1rem solid #BDBDBD;
    input {

      color: #000;
    }
  }

}
.withdrawal01, .withdrawal02, .withdrawal03 {
  .withdrawal {
    margin-top: 5rem;
  }
  .title-l {
    font-size: 2.2rem;
  }
  
}

.withdrawal03 {
  .withdrawal {
    margin-top: 4.4rem;
    .round-box {
      margin-top: 3.2rem;
      ul {
        li {
          + li {
            margin-top: 1.8rem;
          }
        }
      }
      .overlap {
        margin-bottom: 1.8rem;
      }
      .checkbox2 {
        label {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
        }
      }
    }
  }
  .button-wrap {
    position: initial;
    transform: initial;
    .inactive {
      background-color: $color-grayb-50;
    }
  }
}
.withdrawal01 {
  .text-group {
    .item {
      color: $color-grayb-500;
    }
  }
}
@media (min-width: 720px) {
  .withdrawal01, .withdrawal02, .withdrawal03 {
    .button-wrap {
      padding-bottom: 2rem;
    }
  }
  .withdrawal02 {
    .ext-text-light-gray {
      padding-top: 0.3rem !important;
      margin: 0 !important;
      &:first-of-type {
        padding-top: 0;
      }
    }
  }
  .withdrawal03 {
    .button-wrap {
      padding-bottom: 0;
    }
  }
}