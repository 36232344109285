@import '_variables';
.community {
    width: 100%;
    height: 100%;
    .real-time-wrap {
        width: 100%;
        height: 4rem;
        overflow: hidden;
        background: #f8f8f8;
        .adm-swiper-item,
        .adm-swiper-slide,
        .adm-swiper-track {
            height: 4rem;
        }
        .adm-swiper-item {
            display: flex;
            padding-left: 2rem;
        }
        a {
            // padding: 0.8rem 4rem 0 2rem;
            width: 100%;
            display: flex;
            gap: 0.4em;
            align-items: center;
            background-color: #f8f8f8;
            font-size: 1.4rem;

            .subject {
                display: -webkit-box;
                font-size: 1.4rem;
                font-weight: 400;
                color: #202020;
                position: relative;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            &:after {
                content: '';
                background: url('../images/arrow-right-small.svg') no-repeat;
                width: 2.4rem;
                height: 2.4rem;
                position: absolute;
                top: 50%;
                right: 1.6rem;
                transform: translateY(-50%);
                // padding-bottom: 0.3rem;
            }
        }
        .empty__data {
            a:after {
                background: none;
            }
        }
    }
    .community-tab {
        width: 100%;
        margin: 1rem 0 1.4rem;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        ul {
            padding-left: 2rem;
            position: relative;
            min-width: 15rem;
            display: flex;
            gap: 1rem;
            li {
                position: relative;
                vertical-align: middle;
                white-space: nowrap;
                button {
                    height: 2.8rem;
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 1;
                    border-radius: 3.7rem;
                    padding: 0.45rem 0.5rem 0.45rem 1rem;
                    display: flex;
                    align-items: center;
                    gap: 0.6rem;
                    border: 0.1rem solid $color-grayb-300;
                    color: $color-grayb-500;
                    min-width: 10rem;
                    img {
                        flex: 0 0 2.4rem;
                        max-width: unset;
                    }
                    &.active {
                        border: 0.1rem solid #2ebf93;
                        color: #2ebf93;
                    }
                }
            }
        }
    }
}

.community-writing {
    width: 100%;
    padding: 1.6rem 2rem 5rem;
    .category {
        a {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.4em;
            padding-bottom: 1.25rem;
            margin-bottom: 0.8rem;
            border-bottom: 0.02rem solid #e0e0e0;
            .category-title {
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #000;
            }
            .category-list {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
            }
        }
    }

    .writing-textarea {
        .img-wrap {
            .img-count {
                text-align: right;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-grayb-500;
                margin-bottom: 1.6rem;
                .count,
                .total {
                    color: $color-grayb-500;
                }
            }
            .img-list {
                width: 100%;
                margin-bottom: 1.6rem;
                overflow-x: scroll;
                ul {
                    display: flex;
                    gap: 0.8rem;
                    min-width: 10rem;
                    li {
                        position: relative;
                        width: 7rem;
                        height: 7rem;
                        border-radius: 0.8rem;
                        overflow: hidden;
                        &:has(.adm-image-img) {
                            border-radius: 0.6rem;
                        }
                        > button {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                        .adm-image {
                            width: 100%;
                            height: 100%;
                        }
                        &.add-img {
                            button {
                                width: 7rem;
                                height: 7rem;
                                border-radius: 0.6rem;
                                border: 0.05rem dashed $color-grayb-500;
                                background-color: $color-grayb-50;
                            }
                        }
                        .img-delete {
                            position: absolute;
                            top: 0;
                            right: 0;
                            img {
                                vertical-align: top;
                            }
                        }
                    }
                }
            }
        }
    }
    .keyword-box {
        padding-top: 1.6rem;
        border-top: 0.02rem solid $color-grayb-400;
        .title-xxs {
            gap: 0.2rem;
            padding-left: 0.5rem;
            span:first-child {
                font-size: 1.4rem;
                font-weight: 700;
            }
            span:last-child {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        ul {
            padding: 1.1rem 0 0;
        }

        .checkbox-list {
            ul {
                padding: 1.1rem 0.2rem 0 0.2rem;
                li {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.6rem 0.4rem;
                }
            }
        }
    }
}

.write {
    width: 100%;
    padding: 0 0 10rem;

    .content {
        padding: 0 2rem;
        .subject {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 2.8rem 0 0.8rem;
        }
        .text {
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1.6rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }
        .img-slide {
            text-align: center;
            margin-bottom: 1.6rem;
        }
        .keyword {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            flex-wrap: wrap;
            margin-bottom: 1.6rem;
            overflow-x: auto;
            li {
                padding: 0.5rem 1.2rem 0.6rem 1.2rem;
                border-radius: 3.2rem;
                color: #2ebf93;
                border: 0.1rem solid $color-main-500;
                font-size: 1.2rem;
                font-weight: 400;
                white-space: nowrap;
            }
        }
    }
    .write-box {
        padding: 0 2rem;
        .write-info {
            padding: 1.2rem 2.65rem;
            display: flex;
            justify-content: space-between;
            border-top: 0.02rem solid #dbe2df;
            border-bottom: 0.02rem solid #dbe2df;
            margin-bottom: 3rem;
            li {
                gap: 0.4rem;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $color-grayb-400;
                img {
                    margin-right: 0.2rem;
                }
                span {
                    color: $color-grayb-400;
                }
            }
        }
    }
    .repl-box {
        p.delete {
            color: $color-grayb-500;
        }
        .default {
            padding: 12.4rem 0;
            font-size: 1.4rem;
            font-weight: 400;
            text-align: center;
            color: $color-grayb-500;
        }
        .repl-list {
            padding-bottom: 4.5rem;
            .repl-info {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .repl {
                    padding: 0 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;
                    .repl-box {
                        .repl-header {
                            margin-bottom: 1.1rem;
                        }
                        .repl-content {
                            padding-left: 5.8rem;
                            margin-bottom: 1.3rem;
                            p {
                                font-size: 1.4rem;
                                font-weight: 400;
                                word-break: break-all;
                            }
                        }
                    }
                    .repl-util-pd {
                        padding-left: 5.8rem;
                        display: flex;
                        gap: 1.7rem;
                        align-items: center;
                    }
                    .repl-util {
                        button {
                            display: flex;
                            align-items: center;
                            gap: 0.2rem;
                            font-size: 1.2rem;
                            line-height: 1;
                            font-weight: 400;
                            color: $color-grayb-600;
                        }
                    }
                    .repl-more {
                        margin-top: 1.5rem;
                        font-size: 1.2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #2ebf93;
                    }
                }
                .add-repl {
                    padding-right: 0;
                    padding-left: 5.8rem;
                    .repl-box {
                        .repl-content {
                            padding-left: 4.8rem;
                        }
                        .repl-util-pd {
                            padding-left: 4.8rem;
                        }
                    }
                    &:has(.repl-more) {
                        .add-repl {
                            gap: 1.3rem;
                        }
                        .repl-more {
                            margin-top: 0;
                            display: flex;
                            align-items: center;
                            gap: 0.4rem;
                            img {
                                padding-bottom: 0.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: $pcVersion) {
    .write {
        .repl-box {
            .default {
                padding: 9.6rem 0 20rem 0;
            }
        }
    }
}
@media (min-width: 720px) {
    body:has(.community) {
        .wrap.community-detail {
            height: initial !important;
        }
    }
}
.writer-wrap {
    padding: 0 2rem;
    &.community {
        padding: 0;
    }
    .label-bg-g {
        margin-bottom: 0.8rem;
    }
    .desc {
        margin-bottom: 0.8rem;
    }
}
.writer-box {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    .profile-img {
        width: 4.2rem;
        height: 4.2rem;
        img {
            width: 4.2rem;
            height: 4.2rem;
        }
    }
    .writer-info-wrap {
        .info {
            display: flex;
            align-items: center;
            gap: 0.4em;
            margin-bottom: 0.4rem;
            .nickname {
                font-size: 1.6rem;
                font-weight: 700;
            }
            .level {
                font-size: 1rem;
                font-weight: 400;
            }
            .bg-color {
                display: block;
                width: 1.4rem;
                height: 1.4rem;
                background-color: #879ac1;
                border-radius: 0.4rem;
            }
        }
        .util {
            font-size: 1.2rem;
            font-weight: 400;
            color: $color-grayb-600;
            .time {
                margin-right: 0.5rem;
            }
        }
    }
    .more {
        margin-left: auto;
    }
}
.adm-swiper-track-inner {
    // margin-top: 0.3rem;
    // .community list: text banner. 야이템별 수직중앙 맞추기위함
}
.community-detail {
    padding-bottom: 7.9rem;
    .write {
        padding: 0;
        > .repl-box {
            margin-top: 2.8rem;
        }
    }
    .like-active {
        color: $color-main-500 !important;
    }
}

.detail-info-wrap {
    padding: 0 2rem;
    ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-top: 0.1rem solid $color-grayb-200;
        border-bottom: 0.1rem solid $color-grayb-200;
        // border-bottom: 0.02rem solid $color-grayb-600; 피그마
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 0.4rem;
            padding: 1.2rem 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: $color-grayb-600;
            img {
                margin-right: 0.2rem;
                width: 1.2rem;
                height: 1.2rem;
            }
            span {
                color: $color-grayb-400;
            }
        }

        li.like {
            cursor: pointer;
        }
    }
}

.neigbor {
    section {
        height: 100%;
        &:nth-of-type(1) {
            margin-bottom: 2.4rem;
        }
        .list-content {
            &:has(.info) {
                border: 1px solid red;
                font-size: 1.8rem;
                color: #ddd;
                text-align: center;
            }
        }
    }
}

@media (min-width: 720px) {
    .wrap.community-detail {
        // height: calc(100vh - 10rem);
    }
    .wrap.popular {
        min-height: 100vh;
        // 리스트 데이터 없을 때도 바탕 흰색으로 차기
        height: 100%;
        .real-home & {
            min-height: auto;
            // 메인에서는 해당안되게
        }
    }
}
