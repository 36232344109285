@import "_variables";
//mcb font library에 따른 작명, 스타일
@mixin head-h1 ($font-color){
    font-weight: 700;//bold
    font-size: 2.6rem;
    color: $font-color;
  }
@mixin head-h2 ($font-color){
font-weight: 700;//bold
font-size: 2.4rem;
color: $font-color;
}
@mixin head-h3 ($font-color){
font-weight: 700;//bold
font-size: 2.2rem;
color: $font-color;
}
@mixin head-h4 ($font-color){
font-weight: 700;//bold
font-size: 2rem;
color: $font-color;
}
@mixin head-h5 ($font-color){
font-weight: 700;//bold
font-size: 1.8rem;
color: $font-color;
}
@mixin head-h5-m ($font-color){
font-weight: 400;//Regular
font-size: 1.8rem;
color: $font-color;
}


@mixin sub-title-bold ($font-color){
font-weight: 700;// medium
font-size: 1.6rem;
color: $font-color;
}
@mixin sub-sub ($font-color){
font-weight: 500;// medium
font-size: 1.6rem;
color: $font-color;
}


@mixin body-bold ($font-color){
font-weight: 700;// bold
font-size: 1.4rem;
color: $font-color;
}
@mixin body-me ($font-color){
font-weight: 500;// medium
font-size: 1.4rem;
color: $font-color;
}


@mixin caption-noto ($font-color){
font-weight: 500;// medium
font-size: 1.2rem;
color: $font-color;
}
@mixin caption-point_caption_Bold ($font-color){
font-family: 'Gmarket Sans';
font-weight: 700;// bold
font-size: 1rem;
color: $font-color;
}
@mixin caption-point_caption_Re ($font-color){
font-family: 'Gmarket Sans';
font-weight: 500;// medium
font-size: 1rem;
color: $font-color;
}


@mixin logo-h1 ($font-color){
font-weight: 800;// ExtraBold
font-size: 2.6rem;
color: $font-color;
line-height: normal;
letter-spacing: -2.6px;
}
@mixin logo-h2 ($font-color){
font-weight: 800;// ExtraBold
font-size: 2rem;
color: $font-color;
}


//라벨
@mixin label-style {
display: inline-block;
height: 2rem;
font-family: GmarketSansMedium;
font-size: 1rem;
font-weight: 500;
letter-spacing: -0.06rem;
border-radius: 0.5rem;
padding: 0.4rem;
}
.label-l {
@include label-style;
line-height: 1.4;
margin-right: 0.4rem;
background: $color-main-50;
color: $color-main-600;
}
.label-l2 {
@include label-style;
line-height: 1.4;
background: $color-main-50;
color: $color-main-600;
}
.label-bg-g {
@include label-style;
margin-right: 0.4rem;
background: $color-main-50;
color: $color-main-600;
line-height: 1.3;
font-weight: 400;
}
.label-border-b {
@include label-style;
border: 0.1rem solid $color-info-200;
color: $color-info-500;
}
.label-border-r {
@include label-style;
border: 0.1rem solid rgba(255, 51, 51, 0.50);
color: $color-error;
}
.label-border-gr{
@include label-style;
border: 0.1rem solid #C7C7C7;
color: #C7C7C7;
}
.label-border-y{
@include label-style;
border: 0.1rem solid $color-warning-200;
color: $color-warning-500;
}


@mixin toast-message {
    padding: 1.2rem 1.6rem;
    width:26.8rem;
    height:4.2rem;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // content:'추천 코드가 복사되었습니다. \a 다른 곳에 복사 붙여넣기가 가능합니다.';
    content:attr(data-content);
    white-space: pre;
    border-radius: 0.4rem;
    background-color: $black;
    box-shadow: 0 0.3rem 0.8rem 0 rgba(0, 0, 0, 0.20);
    color:$white;
    font-size:1.4rem;
    font-weight:300;
  }
  