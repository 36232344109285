html {
	font-size:62.5%;
}
.layout-content { 
  /* height: 100%; */
  -webkit-tap-highlight-color:rgba(255,255,255,0); /* 버튼 Touch 시 나오는 음영 지우기 */
  user-select: none; /* select 안되게 하기 */
  -webkit-touch-callout: none; /* link Long touch 막기 */
}
.layout-body {
  position:absolute; 
  top: 4.8rem; /* header height */
  bottom: 5.6rem; /* footer height */
  /* left:0; */
  left: 50%; /* center */
  transform: translate(-50%, 0); /* center */
  width:100%;
  max-width: 72rem;
  background: #ffffff;
  /* overflow:auto; */
}

.layout-body.no-header{
  top:0px; /* header height */
}
.layout-body.no-footer{
  bottom:0px; /* footer height */
}

.layout-scroll-body {
  position:absolute; 
  width:100%;
  padding:0;
  -webkit-overflow-scrolling:touch;
  /* overflow:auto; */
  /* background: red; */
}
.layout-header {
  position: fixed;
  z-index:1;
  /* left: 0; */
  top: 0;
  left: 50%; /* center */
  transform: translate(-50%, 0); /* center */
  width: 100%;
  max-width: 72rem;
  height: 4.8rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
}
.layout-header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-header-logo .logo {
  /* width: 32px;
  height: 3.2rem; */

}
.layout-header-logo .logo-text {
  height: 3.2rem;
  margin-left: 0.8rem;
  font-family: 'Jua';
  font-size: 2.5rem;
  color: #40DF9F;
  line-height: 3.4rem;
  
  /* 수정 */
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  font-size: 1.8rem;
}
.layout-header-logo .menu-title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
}
.layout-header-nav-bar {
  display: flex;
}
.layout-header-nav-bar-box {
  cursor: pointer;
  /* width: 36px;
  height: 3.6rem; */
  /* background-color: red; */
}
.layout-footer {
  position: fixed;
  z-index: 100;
  /* left: 0; */
  bottom: 0;
  left: 50%; /* center */
  transform: translate(-50%, 0); /* center */
  width: 100%;
  max-width: 72rem;
  height: var(--bottom-height);
  background-color: #ffffff;
  display: flex;
  align-items:center;
  justify-content: space-around;

  /* 추가 */
  /* box-shadow: 0 1.9rem 3.8rem 0 rgba(0, 0, 0, 0.30); */
  /* border-top: 0.1rem solid var(--gray-t-100, #EAEEED); */
  border-radius: 2.4rem 2.4rem 0 0;

}
.layout-footer-nav-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}