$color-main-50: #F1FCF6;
$color-main-400: #6DEBAC;
$color-main-500: #40DF9F;
$color-main-600: #2EBF93;
$color-danger-100:#FFF8F9;
$color-danger-200:#FFC0BB;
$color-holiday-not-thismonth:#FFC0BB;
$color-danger-500:#FF567B;
$color-holiday:#FF567B;
$color-error:#FF3333;

$color-warning-100:#FFF9EE;
$color-warning-200:#FFE5A6;
$color-warning-500:#FFA323;
$color-second-800:#6357E3;
$color-info-100:#F0FCFD;
$color-info-200:#ADECF1;
$color-info-500:#3CC4D0;
$color-info-600:#35AFBA;

$color-grayb-50: #FAFAFA;
$color-grayb-100: #F5F5F5;
$color-grayb-200: #EEEEEE;
$color-grayb-300: #E0E0E0;
$color-grayb-400: #BDBDBD;
$color-grayb-500: #9E9E9E;
$color-grayb-600: #757575;
$color-grayb-700: #616161;
$color-grayb-800: #424242;
$color-grayb-900: #212121;

$color-grayt-100: #F8FAF9;
$color-grayt-300: #CAD5D1;
$color-grayt-400: #B8C7C1;
$color-grayt-500: #A4B7AF;
$color-grayt-600: #8DA59B;
$color-grayt-800: #5D7068;
// grayt계열 없어지고 grayb로 font-color대체 (2차수정)


$color-today-date: #788991;
$bgcolor-today-date: #F8F8F8;
$popup-content-list-line:#f5f5f5;
$bottom-height: 7.3rem;  /* 56 + 34/2px */
$color-text: #202020;
$mobileVersion: 719px;
$pcVersion: 720px;
$white:#ffffff;
$black:#202020;
$black00: #000;
$analysis-bar01: #40DF9F;
$analysis-bar02: #3BF5DF;