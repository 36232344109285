@import "_variables";
@import "mixin";
.wrap.calendar-wallet{
  padding-bottom:10rem;
}
.wallet{
  width: 100%;
  height: 100%;
  &:has(.ad-area.h160) {
      .wallet-view {
        margin-top: 6rem;
      }
    }
  .mail-wrap{
    padding: 0 2rem;
    .mail-box{
      width: 100%;
      padding-top: 5.6rem;
      .title{
        margin-bottom: 6.4rem;
        .title-m{
          margin-bottom: 0.8rem;
        }
        .reference{
          display: flex;
          align-items: center;
          gap: 0.8rem;
          .reference-s2{
            font-size: 1.2rem;
            font-weight: 500;
            color: #BDBDBD;
          }
          a{
            font-size: 1.4rem; 
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #2EBF93;
            display: block;
          }
        }
      }
      .data-radio{
        .title-xs{
          margin-bottom: 0.8rem;
        }
        p{
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #FF567B;
          margin-bottom: 2.4rem;
        }
        .radio-wrap{
          display: flex;
          gap: 5.7rem;
          align-items: center;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 18.8rem;
          .ico-radio{
            margin-right: 0.8rem;
          }
        }
      }
    }

  }
  .statistics-wrap{
    width: 100%;
    margin-top: 2.4rem;
    .month-spending{
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #2EBF93;
      padding: 0 2rem;
    }
    .month-asset{
      margin-bottom: 3.2rem;
      padding: 0 2rem;
      .balance{
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .budget{
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #847BE9;
      }
    }
  }
  .statistics-wrap{

    .title-m{
      margin-bottom: 0.4rem;
    }
    .reference{
      margin-bottom: 4rem;
    }

    .graph1{
      padding: 0 2rem 4rem;
      margin-bottom: 4rem;
      border-bottom: 2rem solid $color-grayb-50;
      .circle-graph{
        margin-bottom: 4rem;
        text-align: center;
      }
      .info{
        ul{
          li{
            display: flex;
            align-items: center;
            gap: 0.8rem;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            + li{
              margin-top: 0.8rem;
            }
            .color{
              background-color: $color-main-500;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
            }
            .point-number{
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              color: #E0E0E0;
            }
            .balance{
              margin-left: auto;
              text-align: right;
            }
          }
        }
      }
    }

    .graph2{
      > .title-m, .reference {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      > .reference {
        margin-bottom: 0;
      }
      margin-bottom: 4rem;
      border-bottom: 2rem solid $color-grayb-50;
      .col-bar-graph{
        width: 100%;
        ul{
          display: flex;
          gap: 1.6rem;
          width: 100%;
          li{
            width: calc(100%/ 6);
            .bar-box{
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 0.8rem;
              align-items: center;
              .bar{
                position: relative;
                width: 100%;
                height: 20rem;
                border-radius: 0.6rem;
                margin-top: auto;
              }
              .amount{
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                color: $color-grayb-600;
                position: absolute;
                top: -3rem;
                left: 50%;
                transform: translate(-50%, 0);
              }
              .month{
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
              }
              .over{
                position: absolute;
                border-radius: 0.6rem 0.6rem 0 0;
                display: block;
                bottom: 0;
                width: 100%;
                background: #eee;
              }

              &.bar-active{
                .bar{
                  position: relative;
                  width: 100%;
                  height: 20rem;
                  border-radius: 0.6rem;
                  margin-top: auto;
                }
                .amount{
                  font-size: 1.2rem;
                  font-style: normal;
                  font-weight: 400;
                  color: #2EBF93;
                }
                .month{
                  font-size: 1.2rem;
                  font-style: normal;
                  font-weight: 400;
                  color: #2EBF93;
                }
                .over{
                  position: absolute;
                  border-radius: 0.6rem 0.6rem 0 0;
                  display: block;
                  bottom: 0;
                  width: 100%;
                  background: $color-main-500;
                }
              }
            }
          }
        }
      }
    }
    .graph3{
      padding: 0 2rem 2.4rem;
      margin-bottom: 1.6rem;
      border-bottom: 2rem solid $color-grayb-50;
      .row-bar-graph{
        width: 100%;
        ul{
          display: flex;
          gap: 1.6rem;
          flex-direction: column;
          width: 100%;
          li{
            .pet-name{
              font-size: 1.4rem;
              font-weight: 700;
              margin-bottom: 0.4rem;
              &.active-animal {
                color: $color-main-500;
              }
            }
            .bar-box{
              width: 100%;
              display: flex;
              gap: 0.8rem;
              align-items: center;
              position: relative;
              .bar{
                position: relative;
                display: flex;
                left: 0;
                width: 100%;
                height: 2rem;
                border-radius: 0 0.6rem 0.6rem 0;
                flex-direction: column;
                margin-top: auto;
              }
              .amount{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                z-index: 99;
                margin-left: 0.4rem;
                font-size: 1.2rem;
                font-weight: 500;
                color: $color-grayb-500;
                line-height: 0.8;
              }
              .month{
                font-size: 1.4rem;
                font-weight: 700;
              }
              .over{
                position: absolute;
                min-width: 2.8rem;
                max-width: 100%;
                height:100%;
                border-radius: 0 0.4rem 0.4rem 0;
                display: block;
                bottom: 0;
                background: #eee;
              }
              &.bar-active{
                .bar{
                  position: relative;
                  width: 100%;
                  height: 2rem;
                  border-radius: 0 0.6rem 0.6rem 0;
                  margin-top: auto;
                }
                .amount{
                  font-size: 1.2rem;
                  font-style: normal;
                  font-weight: 400;
                  color: #fff;
                }
                .over{
                  position: absolute;
                  border-radius: 0 0.4rem 0.4rem 0;
                  display: block;
                  bottom: 0;
                  background: $color-main-500;
                }
              }
            }
          }
        }
      }
      .spread-warp{
        margin-top: 1.6rem;
        button{
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #2EBF93;
          img{
            vertical-align: -0.6rem;
          }
        }
      }
      .switch-box3{
        text-align: center;
        margin: 2.4rem 0 0;
        .switch {
          > div {
            justify-content: center;
          }
        }
      }
    }
    .text-p{
      padding: 0 2rem 4rem;
      font-size: 1.2rem;
      font-weight: 700;
      .label-l, .label-bg-g{
        margin-left: 0.4rem;
      }
    }
    .row-bar-graph {
      ul {
        li {
          display: grid;
          grid-template-columns: 2.5fr 1fr;
          grid-template-rows: 1fr 1fr;
          gap: 0.6rem;
          .pet-name {
            grid-row: 1 / 2;
            margin: 0 !important;
          }
          .bar-box {
            grid-row: 2 / 3;
          }
          .month {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: $black00;
            font-size: 1.4rem;
            font-weight: 700;
            ;
          }
        }
      }
    }
  }
  .analysis-bar01, .analysis-bar02 {
    border-radius: 0 0.4rem 0.4rem 0 ;
    .amount {
      color: $white !important;
    }
    .over {
      min-width: 2.8rem;
      max-width: 100%;
    }
  }
  .analysis-bar01 {
    .over{
      background-color: $analysis-bar01 !important;
    }
  }
  .analysis-bar02 {
    .over {
      background-color: $analysis-bar02 !important;
    }
  }
  .wallet-view{
    margin-top:7.4rem;
    height: calc(100% - 7.4rem); 
    // height: auto;
    .wallet-con{
      padding: 2rem 1.8rem 2.2rem 2.2rem;
      margin-bottom: 6rem;
    }
    .wallet-con-add{
      padding: 0.4rem 1.8rem 2.2rem 1.8rem;
    }
    
    .total-wrap{
      padding-top: 0.8rem;
      padding-bottom: 5rem;
      gap: 0.4rem;
      span{
        font-size: 1.4rem;
        font-weight: 400;
      }
      strong{
        font-size: 1.6rem;
        font-weight: 700;
        color: #2EBF93;
      }
    }
  }
  //캘린더
  .calendar-wallet{
    width: 100%;
    // padding: 0 2rem;
    .calendar-wrap{
      .calendar-header{
        padding: 0 0.8rem 0 2rem;
        align-items: center;
        gap: 0.6rem;
        .graph-button{
          margin-left: auto;
        }
      }
    }
    .calendar-month-wrap{
      width: 100%;
      padding: 3rem 2rem 0 2rem;
      .calendar{
        .date_area{
          .head{
            display: flex;
            justify-content: space-around;
            padding-bottom: 1rem;
            li{
              width: calc(100% / 7);
              text-align: center;
              height: 4.3rem;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: #9E9E9E;
            }
          }
          .list{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.6rem;
            li{
              width: calc(100% / 7);
              text-align: center;
              height: 6.4rem;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-top: 0.1rem solid #F5F5F5;
              span{
                margin-bottom: 0.6rem;
                color: #BDBDBD;
                &.point-r{
                  color: #FF567B;
                }
              }
              &.active{
                span{
                  display: inline-block;
                  width: 2rem;
                  height: 2rem;
                  margin: 0.4rem 0;
                  color: #000;
                }
              p{
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #2EBF93;
              }
                &.fromday{
                  padding-top: 0.2rem;
                  
                  span{
                    margin-bottom: 0.2rem;
                    background-color: $color-main-500;
                    border-radius: 5rem;
                    color: #fff;
                  }
                }
            }
          }
        }
      }
    }
  }
    .asset-wrap{
      display: flex;
      justify-content: center;
      gap: 6rem;
      text-align: center;
      > div{
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        p{
          color: #9E9E9E;
        }
      }

      .budget{
        img{
          vertical-align: baseline;
        }
        .amount{
          color: #847BE9;
        }
      }

      .spending{
        .amount{
          color: #2EBF93;
        }
      }

      .total{
        .amount{
          color: #000;
        }
      }
    }
  }
}

.note-share-wrap{
  margin-top: 5.6rem;
  input[type=text] {
    // 기본
    &::placeholder {
      color: $color-grayb-600;
    }
    /* input에 값 없을 때 */
    &:placeholder-shown {
     border-bottom: 1px solid $color-grayb-600; 
    }
    /* input에 값 있을 때 */
    &:not(:placeholder-shown) {
      color: $black00;
      border-bottom: 1px solid $black00; 
    }
    // 포커스될 때. 이걸 :not(:placeholder-shown)뒤에 넣어야 우선순위 높아져서 적용됨.
    &:focus {
      caret-color: $color-main-500;
      border-bottom: 2px solid $color-main-500; 
    }
  }

  .button-wrap{
    .text-left{
      text-align: left;
      &.faq-bottom{
        margin-bottom: 1.6rem;
        a{
          vertical-align: baseline;
        }
        .info-g:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}
.note-share-give{
  .join{
    padding-bottom:0;
  }
  .content.can-not-invite{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content.invite-code{
    
    text-align: center;
    p:nth-of-type(1){
      font-size: 4rem;
      color:$color-main-500;
      font-weight:600;
    }
    p:nth-of-type(2){
      font-size: 1.4rem;
      color:#BDBDBD;
      .invite-count{
        vertical-align: baseline;
        color:$color-main-600;
        font-weight:600;
        padding: 0 0.8rem;
      }
    }
  }
}
.can-not-invite, .invite-code{
  height: calc(100vh - (4.8rem + 5.6rem + 19rem));
  .wallet-manage-member & {
    height: calc(100vh - (36rem));
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .can-not-invite-con {
    text-align: center;
    p:nth-of-type(1){
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color:$black;
    }
    p:nth-of-type(2){
      margin-top: 0.4rem;
      
      a{
        vertical-align: baseline;
      }
    }
  }
}

.wallet-manage-member{
  .meeting{
    margin-bottom: 2rem;
    &:nth-of-type(2) {
      li {
        padding-top: 0.8rem;
        padding-bottom: 1.6rem;
      }
    }
    &:nth-of-type(3) {
      li {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
      }
    }
    ul{
      display: flex;
      flex-direction: column;
      gap: 1.8rem 0;
      li{
        text-align: left;
        &.list-item{
          padding: 0.8rem 0;
        }
        &.member-list{
          display:flex;
          align-items: center;
          justify-content: space-between;
        }
        .writer-info-wrap {
          margin-top: 0;
        }
        .disconnect-share{
          button{
            padding: 0.4rem 0.8rem;
            background-color: $color-grayb-50;
            color:#BDBDBD;
            font-weight:600;
            font-size: 1.4rem;
            border-radius: 3.7rem;
          }
        }
        &.title-wrap{
          padding:0;
          display: flex;
          gap: 0.4rem;
          line-height: 1.5;
          .title, .member-count{
            font-size: 1.4rem;
            color:$color-grayb-500;
            font-weight:400;
          }
        }
        
        .profile-img{
          img{
            width: 5.6rem;
            height: 5.6rem;
          }
        }
        .info{
          display:flex;
          align-items: center;
          gap: 0.4rem;
          .nickname{
            font-size: 1.6rem;
            font-weight:600;
          }
          .level{
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
          }
        }
      }
      // .profile-box{
      //   display:flex;
      //   align-items: center;
      //   gap: 1.6rem;
      // }
      // .group-owner, .group-member{
      //   padding: 0.8rem 0;
      // }
    }
  }
}





.wallet-initial{
  padding:0 2rem;
  .title-l{
    margin: 5.6rem 0 8rem 0;
  }
  .faq-bottom {
    line-height:1.5;
    font-size: 1.4rem;
    .info-b {
      span {
        color: $color-grayb-600;
        font-weight: 700;
        font-size: 1.6rem;
      }
    }
    .info-g {
      @include body-me ($color-grayb-500);
      margin: initial;
    }
    .point-g {
      @include  body-bold ($color-main-600);
      display: inline-block;
      margin-top: 1.6rem;
    }
    .point-r{
      @include sub-title-bold ($color-danger-500);
      margin: 1.6rem 0;
    }
  }
}
.subscription-link-group a {
  vertical-align: baseline;
}

.subsc-txt-size {
  font-size: 1.2rem;
}

.subsc-txt-gray {
  color: #BDBDBD;
}

.subsc-txt-green {
  color: #2EBF93;
  font-weight: 700;
}

.wallet-initial {
  padding: 0 2rem;
}
.wallet-initial .title-l {
  margin: 5.6rem 0 8rem 0;
}
.wallet-initial .point-r {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.wallet-write-split-month {
  &.type1 {
    margin-top: 6.8rem;
    .join {
      .wallet-write-content {
        margin-top: 7.8rem;
        .join-input {
          input {
            // 기본
            &::placeholder {
              @include body-me ($color-grayb-600);
            }
            /* input에 값 없을 때 */
            &:placeholder-shown {
              @include body-me ($color-grayb-600);
              border-bottom: 0.1rem solid $color-grayb-600;
            }
            /* input에 값 있을 때 */
            &:not(:placeholder-shown) {
              color: $black00;
              border-bottom: 1px solid $black00; 
            }
            // 포커스될 때. 이걸 :not(:placeholder-shown)뒤에 넣어야 우선순위 높아져서 적용됨.
            &:focus {
              caret-color: $color-main-500;
              border-bottom: 2px solid $color-main-500; 
            }
          }

          
          
        }
      }
    }
  }
  &.type2 {
    .list-link-box {
      .link-box {
        li {
          height: 4.3rem;
          label, span {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (min-width: 720px) {
  body:has(.wallet-write-split-month.type2) {
    .fix-bottom {
      bottom: 2rem !important;
    }
  }
}

.data-export, .data-import {
  .title {
    margin: 0 !important;
  }
  .data-radio {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 0.8rem 0;
    
    .profile-text {
      display:flex;
      flex-direction: column;
      // gap: 0.2rem;
      p {
        margin:0 !important;
        &:first-child {
          font-size: 1.6rem;
          color: $black00;
          font-weight: 700;
        }
        &:last-child {
          font-size: 1.4rem;
          font-weight: 500;
          color: $color-main-600;
        }
      }
    }
    &:has(.no-data) {
      justify-content: center;
      text-align: center;
    }
    .no-data {
      p {
        margin: 0 !important;
        font-weight: 400;
        &:first-child {
          @include body-me ($black00);
        }
        &:last-child {
          @include caption-noto ($color-grayb-500);
        }
      }
    }
    &:has(.membership-empty) {
      flex-direction: column;
    }
    .membership-empty {
      width: 100%;
      height: 6.4rem;
      background-color: $color-grayb-50;
      border-radius: 1.6rem;
      .text-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        p {
          @include sub-title-bold ($color-grayb-500);
          margin: 0;
        }
      }
    }
  }
  
  .faq-bottom {
    .info-b {
      span {
        @include sub-title-bold ($color-grayb-600);
      }
    }
    a {
      display: inline-block;
      margin-top: 1.8rem;
    }
    .info-g {
      @include body-me ($color-grayb-500);
      line-height: 1.3;
    }
  }
}
.data-radio {
  .data-export & {
    margin-top: 10rem;
  }
  .data-import & {
    margin-top: 11rem;
  }
}
.account-book-write{
  border-top: 0.05rem solid $color-grayb-200;
  .divider-005-grayb200 {
    margin-top: 1.2rem;
  }
}