@import "_variables";

.search{
  h1{
    margin-bottom: 2.7rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    padding: 0 2rem;
  }
  .recent-search {
    ul{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 1.8rem 1.6rem;
      li{
        // display:grid;
        > p {
          font-size: 1.2rem;
          color: #000;
        }
        .btn-close {
          img {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
  }
  .empty__content {
    img {
      width: 4.8rem;
      height: 4.8rem;
    }
    .empty-search-log {
      color: $color-grayb-400;
      font-size: 1.2rem;
    }
    &:has(.group__imgText) {
      align-items: center;
      .group__imgText {
        text-align: center;
        p:nth-of-type(1) {
          color: #000;
          font-size: 1.4rem;
        }
        p:nth-of-type(2) {
          margin-top: 0.4rem;
          color: $color-grayb-500;
          font-size: 1.2rem;
          white-space: nowrap;
        }
      };
    }

  }

  .search-content {
    .empty-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 22vh auto 30vh;
      >p {
        &.sub{
          color: $color-grayb-400;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  &:has(.btn-more) {
    .log-box:last-of-type {
      margin-bottom: 0.8rem;
    }
    .account-box {
      margin-bottom: 0.8rem;
    }
  }
  // Layout에서 관리하기로 함
  // .faq-bottom2 {
  //   .info-g{
  //     color:#BDBDBD;
  //   }
  // }
}

.recent-search-empty__content {
  .empty-search-log {
    display: block;
    font-size: 1.2rem;
    color: $color-grayb-500;
  }
}
.empty__content {

  .adm-image {
    display: flex;
    justify-content: center;
    padding-bottom: 0.8rem;
  }
  .search-content &, .note-search &{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.community-search, .note-search {
  &:has(.bottom-fixed) {
    height: calc(100% - 9.7rem) !important;
  }
  &:has(.empty__content) {
    height: calc(100% - 20rem) !important;
  }
  &:has(.list-article) {
    .list-article {
      margin-top: 2rem;
    }
  }
}
.note-search:has(.comn-box) {
  .diary-wrap {
    padding: 0 2rem;
  }
  .title-wrap {
    margin-left: 0;
  }
}

.diary-wrap:has(.empty__text) {
  .empty__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12rem;
    background: #FAFAFA;
    border-radius: 1.6rem;
    // border: 1px solid red;
    font-size: 1.2rem;
  }
}

// 가계부
.comn-box:has(.empty__text) {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  .empty__text {
    li {
      margin:0 !important;
      font-size: 1.2rem;
    }
  }
}
.btn-more {
  color: #000;
  .wrap.search &{
    margin: 0;
    width: 100%;
  }
}
.diary-wrap .account-wrap .account-box {
  .wrap.search & {
    grid-template-rows: 1fr;
    padding-top: 2.2rem;
    li {
      margin:0;
      padding-right: 1.8rem;
      padding-left: 1.8rem;
      + li {
        padding-top: 0.8rem;
      }
    }
  }
}