@import "_variables";
@import "mixin";
.notice{
  width: 100%;
  .empty-content{
    display: flex;
    justify-content: center;
    margin-top:70%;
    // align-items: center;
    p{
      font-size: 1.8rem;
      font-weight: 400;
      color:$color-grayb-500;
    }
  }
  // header-tab 탭
  .header-tab{
    margin-bottom: 1.6rem;
    ul{
      display: flex;
      justify-content: center;
      padding: 0 2rem;
      li{
        width: calc(100% / 3);
        text-align: center;
        a{
          display: block;
          padding: 1.4rem 2rem;
          width: 100%;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #BDBDBD;
          &.active{
            border-bottom: 0.2rem solid $color-main-500;
            color: $color-main-500;
          }
        }
      }
    }
  }
  
  .board-list{
    width: 100%;
    ul{
      li{
        a{
          display: block;
          width: 100%;
          padding: 1rem 2rem;
          border-bottom: 0.1rem solid $color-grayb-50;
          &.active{
            background-color: #F1FCF6;
          }
          .content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info{
              .account-book{
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #6357E3;
              }
              .log-book{
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #2EBF93;
              }
              .subject{
                @include sub-title-bold ($black00);
                margin: 0.4rem 0;
              }
              .util{
                display: flex;
                align-items: center;
                gap: 0.4rem;
                // height: 16px;
                > div{
                  font-family: Noto Sans;
                  font-size: 1.2rem;
                  font-weight: 400;
                  color: $color-grayb-400;
                }
                .name{
                  display: flex;
                  align-items: center;
                  gap: 0.4rem;
                  span{
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-grayb-400;
                  }
                }
                strong{
                  display: inline-block;
                  vertical-align: baseline;
                  font-size: 1.2rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }
            }

            &.notice{
              display: initial;
              .info{
                display: flex;
                justify-content: space-between;
                .date{
                  font-family: Noto Sans;
                  @include caption-noto ($color-grayb-600);
                }
              }
              .subject{
                margin: 0.6rem 0 0.4rem 0;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: #000;
              }
              .describe{
                @include caption-noto ($color-grayb-600) ;
              }
            }
            &.community{
              .subject{margin:0}
              .breadcrumb{
                margin-top: 0.8rem;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .label-l {
                  margin-right: 0;
                }
                  .community-title{
                    display: inline-block;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                  }
                }
                .pd-top8{
                  padding-top: 0.8rem;
                }
              }

          }
        }
      }
    }
  }
  &.notice-event {
    .board-list {
      margin-top: 1.6rem;
      border-top: 0.1rem solid $color-grayb-100;
      ul{
        li{
          a{
            display: block;
            width: 100%;
            padding: 1.6rem 2rem;
            border-bottom: 0.1rem solid $color-grayb-100;
            .notice {
              display: flex;
            flex-direction: column;
            gap: 0.8rem;
            align-items:flex-start;
              .subject {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.notice-view{
  background-color: $color-grayb-50;
  padding-top: 4.8rem;
  height: 100%;
  &.faq {
    height: 100%;
    padding-top: 0;
    .subject {
      display: flex;
      align-items: center;
    }
  }
  &.mcb-info {
    .content-wrap {
      display:flex;
      flex-direction: column;
      gap: 3.2rem;
      .text-group {
        display:flex;
        flex-direction: column;
        gap: 1.2rem;
      }
    }
  }
  .title-wrap{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-top: 1.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #E0E0E0;
    .subject{
      font-size: 1.6rem;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }
    .date{
      @include caption-noto ($color-grayb-600);
    }
  }
  .content-wrap{
    padding: 1.6rem 2rem 2rem 2rem;
    background-color: $color-grayb-50;
    font-size: 1.4rem;
    color:#000;
    font-weight: 400;
    line-height: 1.5;
    p{
      margin-top: 4rem;
    }
    .after-editer-api{
      margin-left: 2rem;
      ul{
        li{
          list-style-type: disc;
        }
      }
      ol{
        li{
          list-style-type:decimal;
        }
      }
    }
    .notice-view-img{
      padding: 1.6rem 0;
    }
    .text-group.faq{
      p{
        margin:0;
      }
    }
  }
  &.faq{
    .title-wrap{
      padding: 2.4rem 2rem 2rem 2rem;
      .subject{
        margin:0;
      }
    }
  }
}
.label-text{
  font-size: 1.4rem;
}
.label-text-notice{
  @include body-me ($color-main-600);
}
.label-text-guide{
  @include body-me ($color-danger-500);
}
.label-text-event{
  @include body-me ($color-second-800);
}
